import { CLink } from '@/CLink';
import styled from '@emotion/styled';
import { useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { Error, Form, FormFlex, Input, Label, Thanks } from '../FormComponents';
import { PrimaryBtn, TextBtn } from '../buttons';
import { siteInfo } from './NavLinks';
import { flexSpace, px, py } from './styles/classes';

const TheForm = styled(Form)`
    ${py};
    ${px};
`;

const Buttons = styled.div`
    ${flexSpace};
    margin-top: 32px;
    flex-wrap: wrap;
    gap: 32px;
    max-width: 460px;

    @media (min-width: 1280px) {
        margin-top: 64px;
    }
`;

export const ContactForm = () => {
    const [submit, setSubmit] = useState<'init' | 'submitting' | 'submitted' | 'error'>('init');

    const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmit('submitting');

        const data = Object.fromEntries(new FormData(e.currentTarget));

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(() => {
                setSubmit('submitted');
                e.target.reset();
            })
            .catch(() => {
                setSubmit('error');
            });
    };

    return (
        <TheForm
            onSubmit={handleSubmit}
            submit={submit === 'submitted' || submit === 'error'}
            id="contact-us"
        >
            <h3>Contact Us</h3>
            <Label htmlFor="name">
                <span>
                    Name<strong>*</strong>
                </span>
                <Input type="text" id="name" name="name" placeholder="Full Name" required />
            </Label>

            <FormFlex>
                <Label htmlFor="email">
                    <span>
                        Email <strong>*</strong>
                    </span>
                    <Input
                        type="email"
                        id="email"
                        name="email"
                        required
                        placeholder="your-email@email.com"
                    />
                </Label>
                <Label htmlFor="phone">
                    <span>
                        Phone <strong>*</strong>
                    </span>
                    <PatternFormat
                        type="tel"
                        format="+1 (###) ###-####"
                        customInput={Input}
                        placeholder="123 456-7890"
                        id="phone"
                        name="phone"
                        required
                        pattern="^(\+1)[ ](\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$"
                    />
                </Label>
            </FormFlex>

            <Label htmlFor="message">
                <span>Message</span>
                <Input
                    as="textarea"
                    id="message"
                    name="message"
                    rows={4}
                    placeholder="Write to us..."
                />
            </Label>
            <Buttons>
                <TextBtn as={CLink} to={siteInfo.bookingLink} blue>
                    Schedule Instead
                </TextBtn>
                <PrimaryBtn disabled={submit === 'submitting'} type="submit">
                    {submit === 'submitting' ? 'Sending...' : 'Submit'}
                </PrimaryBtn>
            </Buttons>
            <Thanks submit={submit === 'submitted'} setSubmit={setSubmit} />
            <Error error={submit === 'error'} />
        </TheForm>
    );
};
