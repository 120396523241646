import styled from '@emotion/styled';
import Plain from './Plain';

export default styled(Plain)<{ sm?: boolean; yellow?: boolean; lightYellow?: boolean }>`
    padding: ${({ sm }) => (sm ? '12px 24px' : '16px 64px')};
    background-color: ${({ theme, yellow, lightYellow }) =>
        yellow
            ? theme.colors.yellow[600]
            : lightYellow
              ? theme.colors.yellow[500]
              : theme.colors.blue[600]};
    color: ${({ theme, lightYellow }) =>
        lightYellow ? theme.colors.yellow[900] : theme.colors.white};
    transition:
        color 0.3s ease-in-out,
        background-color 0.3s ease-in-out,
        box-shadow 0.3s ease-in-out;
    text-transform: uppercase;
    border-radius: 100px;
    gap: 16px;
    text-align: center;

    > svg {
        path {
            fill: ${({ theme }) => theme.colors.white};
        }
    }

    :hover {
        background-color: ${({ theme, yellow, lightYellow }) =>
            lightYellow || yellow ? theme.colors.yellow[700] : theme.colors.blue[700]};
        box-shadow: 0px 16px 32px -8px ${({ yellow, lightYellow }) => (lightYellow || yellow ? '#d2b364' : '#1c6ef1')};
        color: ${({ theme }) => theme.colors.white};
    }

    :focus-visible {
        background-color: ${({ theme, yellow, lightYellow }) =>
            lightYellow || yellow ? theme.colors.yellow[700] : theme.colors.blue[700]};
        box-shadow: 0px 16px 32px -8px ${({ yellow, lightYellow }) => (lightYellow || yellow ? '#d2b364' : '#1c6ef1')};
        color: ${({ theme }) => theme.colors.white};
    }

    :active {
        background-color: ${({ theme, yellow, lightYellow }) =>
            lightYellow || yellow ? theme.colors.yellow[800] : theme.colors.blue[800]};
        box-shadow: 0px 8px 16px -4px ${({ yellow, lightYellow }) => (lightYellow || yellow ? '#d2b364' : '#1c6ef1')};
        color: ${({ theme }) => theme.colors.white};
    }
`;
