import { CLink } from '@/CLink';
import { ReactComponent as FacebookIcon } from '@a/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '@a/icons/instagram.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone-filled.svg';
import { ReactComponent as PinIcon } from '@a/icons/pin.svg';
import { ReactComponent as YelpIcon } from '@a/icons/yelp.svg';
import { ReactComponent as YoutubeIcon } from '@a/icons/youtube.svg';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { footerNavLinks, siteInfo } from './NavLinks';
import { flexCenter, flexSpace, flexStart, npx, px, py } from './styles/classes';

const Section = styled.footer`
    ${px};
    ${py};
    padding-bottom: 0 !important;
    background-color: ${({ theme }) => theme.colors.blue[900]};
    color: ${({ theme }) => theme.colors.gray[50]};

    > a:first-of-type {
        display: block;
        max-width: max-content;
        margin: 0 auto 64px;
        transition: opacity 0.3s ease-in-out;

        > .gatsby-image-wrapper {
            max-width: 57.5vw;
        }

        :hover,
        :focus {
            opacity: 0.7;
        }
    }

    @media (min-width: 1280px) {
        > a:first-of-type {
            margin-bottom: 120px;
        }
    }
`;

const Flex = styled.div`
    max-width: 1280px;
    margin: 0 auto;

    @media (min-width: 1024px) {
        ${flexSpace};
        align-items: flex-start;
    }
`;

const Box = styled.div`
    > h4 {
        margin: 0 0 40px;
        text-transform: none;
    }

    > a {
        ${flexStart};
        align-items: center;
        text-decoration: underline;
        transition: color 0.3s ease-in-out;
        margin-bottom: 20px;
        word-break: break-word;
        max-width: max-content;
        color: ${({ theme }) => theme.colors.gray[50]};

        > span {
            ${flexCenter};
            flex-shrink: 0;
            margin-right: 24px;
            width: 32px;
            height: 32px;
            background-color: ${({ theme }) => theme.colors.yellow[100]};
            border-radius: 5px;
            transition: background-color 0.3s ease-in-out;

            > svg {
                flex-shrink: 0;
                width: 16px;
                height: 16px;

                path {
                    transition: fill 0.3s ease-in-out;
                    fill: ${({ theme }) => theme.colors.yellow[600]};
                }
            }
        }

        :last-of-type {
            margin-bottom: 0;
        }

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.blue[200]};

            > span {
                background-color: ${({ theme }) => theme.colors.yellow[200]};

                > svg path {
                    fill: ${({ theme }) => theme.colors.yellow[700]};
                }
            }
        }
    }
`;

const Social = styled.div`
    ${flexStart};
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
    margin-top: 48px;

    > p {
        margin: 0;
        margin-right: 20px;
    }

    > a {
        line-height: 1;
        transition: opacity 0.3s ease-in-out;

        :hover,
        :focus {
            opacity: 0.7;
        }
    }
`;

const Navigation = styled.div`
    margin-top: 64px;

    > h4 {
        margin: 0 0 40px;
        text-transform: none;
    }

    > div {
        max-width: 400px;

        > div {
            ${flexStart};
            flex-direction: column;

            :first-of-type {
                margin-bottom: 24px;
            }

            > a {
                transition: color 0.3s ease-in-out;
                text-transform: capitalize;
                color: ${({ theme }) => theme.colors.gray[50]};

                :not(:last-of-type) {
                    margin-bottom: 24px;
                }

                :hover,
                :focus {
                    color: ${({ theme }) => theme.colors.blue[200]};
                }
            }
        }
    }

    @media (min-width: 1024px) {
        margin-top: 0;

        > div {
            ${flexSpace};
            width: max-content;
            max-width: unset;

            > div:first-of-type {
                margin-bottom: 0;
                margin-right: 80px;
            }
        }
    }
`;

const Copyright = styled.p`
    ${npx};
    text-align: center;
    padding: 16px;
    background-color: ${({ theme }) => theme.colors.blue[950]};
    margin-top: 40px;
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.white};

    @media (min-width: 1024px) {
        margin-top: 64px;
    }

    @media (min-width: 1280px) {
        margin-top: 80px;
    }
`;

export const Footer = () => {
    return (
        <Section>
            <CLink to="/">
                <StaticImage
                    src="../../../assets/images/logo.png"
                    alt="raines over the rockies family dentistry"
                    width={457}
                    height={130}
                />
            </CLink>
            <Flex>
                <Box>
                    <h4>Connect With Us</h4>
                    <CLink to={siteInfo.address.link}>
                        <span>
                            <PinIcon />
                        </span>
                        {siteInfo.address.text}
                    </CLink>
                    <CLink to={siteInfo.phone.link}>
                        <span>
                            <PhoneIcon />
                        </span>
                        {siteInfo.phone.text}
                    </CLink>
                    <Social>
                        <p>Follow us</p>
                        <CLink to={siteInfo.social.facebook} aria-label="our facebook page">
                            <FacebookIcon />
                        </CLink>

                        <CLink to={siteInfo.social.instagram} aria-label="our Instagram page">
                            <InstagramIcon />
                        </CLink>

                        <CLink to={siteInfo.social.yelp} aria-label="our Yelp page">
                            <YelpIcon />
                        </CLink>

                        <CLink to={siteInfo.social.youtube} aria-label="our Youtube page">
                            <YoutubeIcon />
                        </CLink>
                    </Social>
                </Box>
                <Navigation>
                    <h4>Navigation</h4>
                    <div>
                        <div>
                            {footerNavLinks.slice(0, 4).map((data, i) => (
                                <CLink key={i} to={data.link}>
                                    {data.text}
                                </CLink>
                            ))}
                        </div>
                        <div>
                            {footerNavLinks.slice(4).map((data, i) => (
                                <CLink key={i} to={data.link}>
                                    {data.text}
                                </CLink>
                            ))}
                        </div>
                    </div>
                </Navigation>
            </Flex>
            <Copyright>
                © {new Date().getFullYear()} Raines Over The Rockies Family Dentistry. All rights
                reserved.
            </Copyright>
        </Section>
    );
};
