import { css } from '@emotion/react';

export const px = css`
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 768px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (min-width: 1024px) {
        padding-left: 48px;
        padding-right: 48px;
    }

    @media (min-width: 1280px) {
        padding-left: 64px;
        padding-right: 64px;
    }
`;

export const npx = css`
    margin-left: -16px;
    margin-right: -16px;

    @media (min-width: 768px) {
        margin-left: -32px;
        margin-right: -32px;
    }

    @media (min-width: 1024px) {
        margin-left: -48px;
        margin-right: -48px;
    }

    @media (min-width: 1280px) {
        margin-left: -64px;
        margin-right: -64px;
    }
`;

export const py = css`
    padding-top: 32px;
    padding-bottom: 32px;

    @media (min-width: 1024px) {
        padding-top: 48px;
        padding-bottom: 48px;
    }

    @media (min-width: 1280px) {
        padding-top: 64px;
        padding-bottom: 64px;
    }
`;

export const pyb = css`
    padding-top: 32px;
    padding-bottom: 32px;

    @media (min-width: 1024px) {
        padding-top: 64px;
        padding-bottom: 64px;
    }

    @media (min-width: 1280px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
`;

export const ml = css`
    margin-left: 16px;

    @media (min-width: 768px) {
        margin-left: 32px;
    }

    @media (min-width: 1024px) {
        margin-left: 48px;
    }

    @media (min-width: 1280px) {
        margin-left: 64px;
    }
`;

export const mr = css`
    margin-right: 16px;

    @media (min-width: 768px) {
        margin-right: 32px;
    }

    @media (min-width: 1024px) {
        margin-right: 48px;
    }

    @media (min-width: 1280px) {
        margin-right: 64px;
    }
`;

export const flexCenter = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const flexSpace = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const flexStart = css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const leftBorder = css`
    position: relative;
    padding-left: 16px;

    ::before {
        content: '';
        position: absolute;
        width: 8px;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: #3e83f3;
        border-radius: 16px;
    }

    @media (min-width: 1024px) {
        padding-left: 24px;
    }

    @media (min-width: 1280px) {
        padding-left: 64px;

        ::before {
            width: 16px;
        }
    }
`;

export const bottomBorderSm = css`
    position: relative;
    padding-bottom: 20px;

    ::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 128px;
        height: 4px;
        background-color: #3e83f3;
    }
`;

export const bottomBorder = css`
    position: relative;
    padding-bottom: 20px;

    ::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 256px;
        height: 4px;
        background-color: #3e83f3;
    }
`;

export const bgPage = css`
    background-color: #f1f3f4;
    position: relative;

    ::before,
    ::after {
        content: '';
        position: absolute;
        width: 50px;
        height: 50px;
        background-color: #f1f3f4;
        z-index: -1;
        top: -50px;
        left: 0;
    }

    ::after {
        right: 0;
        left: unset;
    }
`;
