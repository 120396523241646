import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { ContactForm } from './ContactForm';
import { flexCenter } from './styles/classes';

const Section = styled.section`
    position: relative;
    isolation: isolate;

    > .gatsby-image-wrapper:first-of-type {
        position: absolute;
        z-index: -1;
        right: 0;
        top: 100px;
        opacity: 0.2;
    }

    @media (min-width: 1024px) {
        ${flexCenter};

        > .gatsby-image-wrapper:last-of-type {
            order: -1;
            border-top-right-radius: 48px;
            flex: 1.7;
            max-width: 800px;
        }

        > form {
            flex: 1;
            min-width: 350px;
            width: 650px;
            box-sizing: content-box;
        }
    }
`;

export const ContactUs = () => {
    return (
        <Section>
            <StaticImage
                src="../../../assets/images/layout/contact-bg.png"
                alt="raines over the rockies dentistry"
                width={475}
                height={484}
            />
            <ContactForm />

            <StaticImage
                src="../../../assets/images/layout/contact-us.jpg"
                alt="raines over the rockies dental clinic"
                width={800}
                height={780}
            />
        </Section>
    );
};
