import { css, Global, Theme } from '@emotion/react';
import '@fontsource/montserrat';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import emotionNormalize from 'emotion-normalize';
import { h1, h2, h3, h4 } from './Typography';

export const theme: Theme = {
    colors: {
        white: '#fff',
        red: '#ED2532',
        yellow: {
            50: '#FFF8E6',
            100: '#FFF0CC',
            200: '#FEE29A',
            300: '#FED367',
            400: '#FEC534',
            500: '#FEC73A',
            600: '#CB9201',
            700: '#986D01',
            800: '#654901',
            900: '#332400',
        },
        blue: {
            50: '#E7F0FE',
            100: '#CFE0FC',
            200: '#9FC1F9',
            300: '#6FA3F6',
            400: '#3F84F3',
            500: '#3E83F3',
            600: '#0C51C0',
            700: '#093D90',
            800: '#062860',
            900: '#031430',
            950: '#010A18',
        },
        gray: {
            50: '#F1F3F4',
            100: '#E3E7E8',
            200: '#C6CED2',
            300: '#AAB6BB',
            400: '#8E9DA4',
            500: '#A8B4B9',
            600: '#5B6A71',
            700: '#445055',
            800: '#2D3539',
            900: '#171B1C',
        },
    },
    spacing: {
        md: '2.22vw', //32px at 1440px wide viewport
        lg: '4.45vw', //64px ^^
        xl: '6.67vw', //96px ^^
        xxl: '8.89vw', //128px ^^
    },
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={() => css`
                ${emotionNormalize};

                html {
                    font-size: 100%;
                }
                body {
                    color: ${theme.colors.blue[800]};
                    font-family: 'Montserrat', sans-serif;
                    font-weight: normal;
                    font-weight: 300;
                    font-size: 1rem;
                    line-height: 160%;
                    word-wrap: break-word;
                    font-kerning: normal;
                    word-wrap: break-word;
                    -webkit-font-smoothing: antialiased;
                }

                h1 {
                    ${h1};
                }

                h2 {
                    ${h2};
                }

                h3 {
                    ${h3};
                }

                h4 {
                    ${h4};
                }

                a {
                    text-decoration: none;
                    color: ${theme.colors.blue[800]};
                    outline: none;
                    background-color: transparent;
                    -webkit-text-decoration-skip: objects;
                }

                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }

                img {
                    max-width: 100%;
                    display: block;
                    margin: 0;
                    padding: 0;
                }

                ul,
                ol {
                    padding-inline-start: 24px;
                }

                button {
                    :disabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                }

                @media (min-width: 1280px) {
                    body {
                        font-size: 1.125rem;
                    }
                }

                @media (min-width: 1600px) {
                    body {
                        font-size: 1.25rem;
                    }
                }
            `}
        />
    );
};
