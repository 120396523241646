import styled from '@emotion/styled';
import Plain from './Plain';

export default styled(Plain)<{ blue?: boolean; yellow?: boolean }>`
    padding: 4px 4px 3px;
    color: ${({ theme, blue, yellow }) =>
        blue ? theme.colors.blue[600] : yellow ? theme.colors.yellow[600] : theme.colors.white};
    transition:
        color 0.3s ease-in-out,
        border-color 0.3s ease-in-out;
    border-bottom: 1px solid transparent;
    text-transform: uppercase;

    :hover {
        border-color: ${({ theme, blue, yellow }) =>
            blue ? theme.colors.blue[700] : yellow ? theme.colors.yellow[700] : theme.colors.white};
        color: ${({ theme, blue, yellow }) =>
            blue ? theme.colors.blue[700] : yellow ? theme.colors.yellow[700] : theme.colors.white};
    }

    :focus-visible {
        border-color: ${({ theme, blue, yellow }) =>
            blue ? theme.colors.blue[700] : yellow ? theme.colors.yellow[700] : theme.colors.white};
        color: ${({ theme, blue, yellow }) =>
            blue ? theme.colors.blue[700] : yellow ? theme.colors.yellow[700] : theme.colors.white};
    }

    :active {
        border-color: ${({ theme, blue, yellow }) =>
            blue ? theme.colors.blue[800] : yellow ? theme.colors.yellow[800] : theme.colors.white};
        color: ${({ theme, blue, yellow }) =>
            blue ? theme.colors.blue[800] : yellow ? theme.colors.yellow[800] : theme.colors.white};
    }
`;
