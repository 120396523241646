import { CLink } from '@/CLink';
import { PrimaryBtn } from '@/buttons';
import { ReactComponent as EmailIcon } from '@a/icons/email.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone-filled.svg';
import { ReactComponent as PinIcon } from '@a/icons/pin.svg';
import styled from '@emotion/styled';
import { siteInfo } from './NavLinks';
import { smTxt } from './styles/Typography';
import { bottomBorder, flexCenter, flexSpace, px, py } from './styles/classes';

const Section = styled.section`
    position: relative;

    @media (min-width: 1024px) {
        ${flexCenter};
    }
`;

const Info = styled.div`
    ${px};
    ${py};
    margin-top: 32px;
    margin-bottom: 40px;
    max-width: 560px;

    > h3 {
        ${bottomBorder};
        margin: 0 0 32px;
    }

    > a.svg-link {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        max-width: max-content;

        > span {
            :first-of-type {
                ${flexCenter};
                flex-shrink: 0;
                margin-right: 24px;
                width: 32px;
                height: 32px;
                background-color: ${({ theme }) => theme.colors.yellow[100]};
                border-radius: 5px;
                transition: background-color 0.3s ease-in-out;

                > svg {
                    flex-shrink: 0;
                    width: 16px;
                    height: 16px;

                    path {
                        transition: fill 0.3s ease-in-out;
                        fill: ${({ theme }) => theme.colors.yellow[600]};
                    }
                }
            }

            :nth-of-type(2) {
                text-decoration: underline;
                transition: color 0.3s ease-in-out;
                word-break: break-word;
            }

            :last-of-type:not(:nth-of-type(2)) {
                ${smTxt};
                text-decoration: none;
                font-style: italic;
                margin-left: 8px;
            }
        }

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.blue[400]};

            > span:first-of-type {
                background-color: ${({ theme }) => theme.colors.yellow[200]};

                > svg path {
                    fill: ${({ theme }) => theme.colors.yellow[700]};
                }
            }
        }
    }

    > p {
        ${flexSpace};
        align-items: flex-start;
        margin: 0 0 16px;

        span {
            :first-of-type {
                font-weight: 700;
            }

            & > em {
                display: block;
                margin-top: -4px;
                font-size: 0.875rem;
                font-weight: 500;
            }
        }

        :nth-last-of-type(2),
        :last-of-type {
            > span:last-of-type {
                color: ${({ theme }) => theme.colors.blue[700]};
            }
        }

        :last-of-type {
            margin-bottom: 32px;
        }
    }

    @media (min-width: 1024px) {
        margin: 0;
        width: 560px;
        flex: 1;
        margin-right: ${({ theme }) => theme.spacing.xxl};
        min-width: 350px;
        padding-right: 0 !important;
    }
`;

const Iframe = styled.iframe`
    border: 0;
    width: 100%;
    height: 100%;
    min-height: 260px;
    max-width: 550px;
    height: 90vw;
    max-height: 500px;

    @media (min-width: 1024px) {
        height: unset;
        min-height: 500px;
        max-height: 866px;
        max-width: 800px;
        aspect-ratio: 800/866;
        border-bottom-left-radius: 48px;
        flex: 2;
    }
`;

export const HoursMap = () => {
    return (
        <Section>
            <Info>
                <h3>Get in Touch</h3>
                <CLink to={siteInfo.address.link} className="svg-link">
                    <span>
                        <PinIcon />
                    </span>
                    <span>{siteInfo.address.text}</span>
                </CLink>
                <CLink to={siteInfo.phone.link} className="svg-link">
                    <span>
                        <PhoneIcon />
                    </span>
                    <span>{siteInfo.phone.text}</span>
                </CLink>
                <CLink to={siteInfo.email.link} className="svg-link">
                    <span>
                        <EmailIcon />
                    </span>
                    <span>{siteInfo.email.text}</span>
                </CLink>
                <p>
                    <span>Monday:</span>
                    <span>
                        8:00 am - 12:00 pm
                        <em>
                            (open for phone call only,
                            <br />
                            closed public)
                        </em>
                    </span>
                </p>
                <p>
                    <span>Tuesday:</span>
                    <span>9:00 am - 05:00 pm</span>
                </p>
                <p>
                    <span>Wednesday:</span>
                    <span>9:00 am - 05:00 pm</span>
                </p>
                <p>
                    <span>Thursday:</span>
                    <span>9:00 am - 05:00 pm</span>
                </p>
                <p>
                    <span>Friday:</span>
                    <span>8:00 am - 02:00 pm</span>
                </p>
                <p>
                    <span>Saturday:</span>
                    <span>Closed</span>
                </p>
                <p>
                    <span>Sunday:</span>
                    <span>Closed</span>
                </p>
                <PrimaryBtn as={CLink} to={siteInfo.bookingLink} sm>
                    Book Now
                </PrimaryBtn>
            </Info>

            <Iframe
                title="raines dental location on map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3071.968613477368!2d-105.0811347234972!3d39.65042090192206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b81cb22443997%3A0xb1982f743e64cadf!2sRaines%20Over%20The%20Rockies%20Family%20Dentistry!5e0!3m2!1sen!2s!4v1711805082775!5m2!1sen!2s"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                id="map"
            ></Iframe>
        </Section>
    );
};
