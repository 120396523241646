export const siteInfo = {
    phone: {
        text: '(303) 988-7800',
        link: 'tel:+13039887800',
    },
    email: {
        link: 'mailto:info@rordental.com',
        text: 'info@rordental.com',
    },
    address: {
        text: '7373 W Jefferson Ave STE 204, Lakewood, CO 80235',
        link: 'https://maps.app.goo.gl/vD1GZ5g36sdudvDE6',
    },
    social: {
        facebook: 'https://www.facebook.com/RORDENTAL/',
        instagram: 'https://www.instagram.com/rordental',
        twitter: '',
        yelp: 'https://www.yelp.com/biz/raines-over-the-rockies-family-dentistry-lakewood',
        youtube: 'https://www.youtube.com/@rainesovertherockiesdentis6400',
        googleReviews:
            'https://www.google.com/search?q=rainesovertherockiesdentis&rlz=1C1CHBF_en-GBPK1096PK1096&oq=rainesovertherockiesdentis&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBBzIwN2owajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x876b81cb22443997:0xb1982f743e64cadf,1,,,,',
    },
    bookingLink: 'https://book.modento.io/c/e3d624c6b0e44d0f8e9ead574dbcd270/Schedule',
};

export type NavLinksTypes = { text: string; link: string; links?: NavLinksTypes }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: '',
        links: [
            {
                text: 'meet the team',
                link: '/about-us/meet-the-team/',
            },
            {
                text: 'meet dr. raines',
                link: '/about-us/meet-dr-raines/',
            },
            {
                text: 'new patients',
                link: '/about-us/new-patients/',
            },
            {
                text: 'financial options',
                link: '/about-us/financial-options/',
            },
            {
                text: 'cherry payment plans',
                link: '/about-us/cherry-payment-plans/',
            },
            {
                text: 'smile club & loyaly program',
                link: '/about-us/raines-over-the-rockies-smile-club/',
            },
            {
                text: 'Before & After',
                link: '/about-us/before-and-after/',
            },
            {
                text: 'reviews',
                link: '/about-us/reviews/',
            },
            {
                text: 'blog',
                link: '/blog/',
            },
        ],
    },
    {
        text: 'services',
        link: '',
        links: [
            {
                text: 'general dentistry',
                link: '/general-dentistry/',
                links: [
                    {
                        text: 'Exams & Cleanings',
                        link: '/general-dentistry/exams-and-cleanings/',
                    },
                    {
                        text: 'Root Canal Therapy',
                        link: '/general-dentistry/root-canal-therapy/',
                    },
                    {
                        text: 'Fillings',
                        link: '/general-dentistry/fillings/',
                    },
                    {
                        text: 'Dental Implants',
                        link: '/general-dentistry/dental-implants/',
                    },
                    {
                        text: 'Gum Therapy',
                        link: '/general-dentistry/gum-therapy/',
                    },
                    {
                        text: 'Sedation Dentistry',
                        link: '/general-dentistry/sedation-dentistry/',
                    },
                    {
                        text: 'Learn More',
                        link: '/general-dentistry/',
                    },
                ],
            },
            {
                text: 'cosmetic dentistry',
                link: '/cosmetic-dentistry/',
                links: [
                    {
                        text: 'Same Day Veneers / Crowns',
                        link: '/cosmetic-dentistry/same-day-veneers-crowns/',
                    },
                    {
                        text: 'Cosmetic Dental Bonding',
                        link: '/cosmetic-dentistry/dental-bonding/',
                    },
                    {
                        text: 'Innovative Teeth Whitening',
                        link: '/cosmetic-dentistry/in-office-whitening/',
                    },
                    {
                        text: 'Cosmetic Dental Implants',
                        link: '/cosmetic-dentistry/cosmetic-dental-implants/',
                    },
                    {
                        text: 'Learn More',
                        link: '/cosmetic-dentistry/',
                    },
                ],
            },
            {
                text: 'implants',
                link: '/implants/',
                links: [
                    {
                        text: 'Full Arch Dental Implant Prosthetic',
                        link: '/implants/full-arch-dental-implant-prosthetics/',
                    },
                    {
                        text: 'Dental Implants',
                        link: '/implants/dental-implant/',
                    },
                    {
                        text: 'Learn More',
                        link: '/implants/',
                    },
                ],
            },
            {
                text: 'browse by concerns',
                link: '/dental-concerns/',
            },
            {
                text: 'technology',
                link: '/technology/',
            },
        ],
    },
    {
        text: 'invisalign',
        link: '/invisalign/',
        links: [
            {
                text: 'about invisalign',
                link: '/invisalign/',
            },
            {
                text: 'why choose us for invisalign',
                link: '/invisalign/why-choose-us-for-invisalign/',
            },
            {
                text: 'invisalign smile assessment',
                link: '/invisalign/invisalign-smile-assessment/',
            },
            {
                text: 'FAQs',
                link: '/invisalign/faqs/',
            },
            {
                text: 'Teen Invisalign',
                link: '/invisalign/invisalign-teen/',
            },
            {
                text: 'Adult Invisalign',
                link: '/invisalign/adult-invisalign/',
            },
        ],
    },
    {
        text: 'Raines esthetics',
        link: '/raines-esthetics/',
    },
    {
        text: 'contact us',
        link: '',
        links: [
            {
                text: 'contact',
                link: '/contact/',
            },
            {
                text: 'request an appointment',
                link: '/request-an-appointment/',
            },
        ],
    },
];

export function getNavLink(text: string) {
    const linkObj = flatLinks.find(data => data.text.toLowerCase() === text.toLowerCase());

    if (linkObj) return linkObj.link;
    else return '';
}

const flatLinks = (() => {
    const flat: NavLinksTypes = [];

    const pushFlat = (arr: NavLinksTypes) =>
        arr.forEach(data => {
            if (!data.links) flat.push(data);
            else {
                if (data.link) {
                    flat.push({
                        text: data.text,
                        link: data.link,
                    });
                }
                pushFlat(data.links);
            }
        });

    pushFlat(navLinks);
    return flat;
})();

export const footerNavLinks: NavLinksTypes = [
    {
        text: 'About Us',
        link: getNavLink('About Us'),
    },
    {
        text: 'meet the team',
        link: getNavLink('meet the team'),
    },
    {
        text: 'dental concerns',
        link: getNavLink('dental concerns'),
    },
    {
        text: 'invisalign',
        link: getNavLink('invisalign'),
    },
    {
        text: 'general dentistry',
        link: getNavLink('general dentistry'),
    },
    {
        text: 'dental implants',
        link: getNavLink('dental implants'),
    },
    {
        text: 'cosmetic dentistry',
        link: getNavLink('cosmetic dentistry'),
    },
    {
        text: 'privacy policy',
        link: '/privacy-policy/',
    },
];
