import { DefaultLayout } from '@s/layouts/DefaultLayout';
import { GatsbyBrowser } from 'gatsby';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => (
    <DefaultLayout {...props}>{element}</DefaultLayout>
);

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {
    // cherry widget doesnt load on subsequent page visit from other pages due to csr
    // prevLocation prevents infinite loop in peculiar situation where it goes into loop which shouldnt happen
    if (location.pathname === '/about-us/cherry-payment-plans/' && prevLocation) {
        setTimeout(() => {
            const widgetContainer = document.getElementById('all');
            if (widgetContainer && !widgetContainer.children.length) window.location.reload();
        }, 200);
    }
};
