import { css } from '@emotion/react';

const styles = css`
    line-height: 122%;
    font-weight: 600;
`;

export const h1 = css`
    ${styles};
    font-size: 2.25rem;

    @media (min-width: 1280px) {
        font-size: 4rem;
    }
`;

export const h2 = css`
    ${styles};
    font-size: 1.75rem;

    @media (min-width: 1280px) {
        font-size: 3rem;
    }
`;

export const h3 = css`
    ${styles};
    font-size: 1.5rem;

    @media (min-width: 1280px) {
        font-size: 2.25rem;
    }
`;

export const h4 = css`
    font-size: 1.125rem;
    line-height: 134.6%;

    @media (min-width: 1280px) {
        font-size: 1.5rem;
    }
`;

export const smTxt = css`
    font-size: 0.875rem;

    @media (min-width: 1280px) {
        font-size: 1rem;
    }
`;

export const lgTxt = css`
    font-size: 1.125rem;

    @media (min-width: 1280px) {
        font-size: 1.25rem;
    }
`;
